import React from 'react'
import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import ReactPlayer from 'react-player'
import { Typography, Stack, Box } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'

import { fetchFromApi } from '../utils/fetchFromApi'
import Videos from './Videos'

const VideoDetail = () => {

  const [VideoDetail, setVideoDetail] = useState({})
  const [videos, setVideos] = useState([])
  const { id } = useParams();

  useEffect(() => {
    fetchFromApi(`videos?part=snippet,statistics&id=${id}`).then((data) => {setVideoDetail({ ...data.items[0].snippet,...data.items[0].statistics });}  )
    fetchFromApi(`search?part=snippet&relatedToVideoId=${id}&type=video`).then((data)=>setVideos(data.items))
    
  }, [id])

  // const {snippet:{title,channelId}} = VideoDetail;


  return (
    <Box minHeight="95vh">

      <Stack direction={{ xs: "column", md: "row" }}>

        <Box flex={1}>

          <Box sx={{ width: "100%", position: "sticky", top: "86px"}} >
            <ReactPlayer url={`https://www.youtube.com/watch?v=${id}`} className="react-player" controls />
            <Typography color="#fff" variant='h5' fontWeight="bold" p={2} >
              {VideoDetail.title}
              {console.log("????", VideoDetail)}
            </Typography>
            <Stack direction='row' justifyContent="space-between" sx={{ color: "#fff" }} py={1} px={2}>

              <Link to={`/channel/${VideoDetail.channelId}`}>
                <Typography variant={{ sm: 'subtitle1', md: 'h6' }} color="#fff" >
                  {VideoDetail.channelTitle}
                  <CheckCircle sx={{ fontSize: "12px", color: "gray", ml: "5px" }} />
                </Typography>
              </Link>

              <Stack  direction={"row"} gap="20px" alignItems={"center"}>
                <Typography variant={"body1"} sx={{opacity:0.7}}>
                   {parseInt(VideoDetail.viewCount).toLocaleString()} views
                </Typography>
                <Typography variant={"body1"} sx={{opacity:0.7}}>
                   {parseInt(VideoDetail.likeCount).toLocaleString()} likes
                </Typography>
              </Stack>

            </Stack>

          </Box>

        </Box>

      <Box px={2} py={{md:1,xs:5}} justifyContent="center" alignItems={"center"}>

        <Videos videos={videos} direction="column"/>

      </Box>
      </Stack>


    </Box>
  )
}

export default VideoDetail
