import React, { useEffect, useState } from 'react'
import { Box,Typography } from '@mui/material'
import Sidebar from './Sidebar'
import Videos from './Videos'
import { fetchFromApi } from '../utils/fetchFromApi'
import { useParams } from 'react-router-dom'

const SearchFeed = () => {

  const [videos,setVideos] = useState([])
  const {searchTerm} = useParams();

  useEffect(()=>{
    fetchFromApi(`search?part=snippet&q=${searchTerm}`).then((data)=>{setVideos(data.items)})

  },[searchTerm])

  return (
    <Box p={2} sx={{overflow:"auto",height:"90vh",flex:2}}>
    <Typography variant='h4' fontWeight='bold' mb={2} sx={{color:"#fff"}}>
      Search results for: <span style={{color:"red"}}>{searchTerm}</span> videos

      <Videos videos={videos}/>

    </Typography>
  </Box>
  )
}

export default SearchFeed
