import { Stack,Box } from '@mui/material'
import React from 'react'
import ChannelCard from './ChannelCard'
import VideoCard from './VideoCard'



const Videos = ({videos,direction}) => {
    // console.log(videos)
  return (
   <Stack direction={direction || 'row'} flexWrap='wrap' justifyContent='space-around' gap={2}>

    {videos.map((item,index)=>(
        <Box key={index}>
            {item.id.videoId && <VideoCard video={item} />}
            {item.id.channelId && <ChannelCard channelDetails={item} />}
        </Box>
    ))}

   </Stack>
  )
}

export default Videos
