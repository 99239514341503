import React from 'react'

const ChannelDetail = () => {
  return (
    <div>
      channeldetail
    </div>
  )
}

export default ChannelDetail
